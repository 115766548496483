import React, { useState } from "react";
import { useRef } from "react";
import { IoNotificationsCircleOutline } from "react-icons/io5";
import { ClipLoader } from "react-spinners";
import PopupView from "../../components/General/PopupPendingView";
import {
  AccessInfo,
  DataListInput,
  FormInput,
} from "../../components/styles/Access";
import {
  DashSearchContainer,
  DashSearchInput,
} from "../../components/styles/Dashboard";
import { GlobalButton } from "../../components/styles/Global";
import { useExternalAPI } from "../../hooks/useExternalAPI";
import { colors } from "../../utils/colors";

const MemberManagement = () => {
  const [addDues, setAddDues] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { initAddMember } = useExternalAPI();
  const [error, setError] = useState("");
  const [change, setChange] = useState(false);
  const formRef = useRef();

  const search = (e) => {
    e.preventDefault();
  };

  const addMember = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const request = await initAddMember({
      emailaddress: e.target[1].value,
      typeofuser: e.target[0].value,
    });

    if (request?.success) {
      setChange(true);
      formRef.current.reset();
    } else {
      setError(request?.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h4
        style={{
          margin: "15px 0",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AccessInfo style={{ margin: "0 20px 0 0" }}>
          Member Management
        </AccessInfo>
      </h4>
      <form
        style={{ fontSize: 14, color: "grey" }}
        onSubmit={(e) => addMember(e)}
        ref={formRef}
      >
        Type *
        <DataListInput style={{ marginBottom: 20 }} required>
          <option value="" disabled selected>
            select account type
          </option>
          <option>LAWYER</option>
          <option>STUDENT</option>
        </DataListInput>
        User email *
        <FormInput
          type="email"
          required
          placeholder="enter email"
          hidden={false}
          style={{ marginBottom: 30 }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <p style={{ fontSize: 12, color: "red", marginTop: 5 }}>{error}</p>
        {loading ? (
          <DashSearchContainer
            style={{
              margin: "10px 0",
              padding: 10,
              alignItems: "center",
              color: "black",
            }}
          >
            Please wait, sending invitation to{" "}
            <span style={{ fontWeight: "bold", margin: 0, marginLeft: 5 }}>
              {email}
            </span>
            ...
            <span style={{ flex: 1 }} />
            <ClipLoader color={colors.primary} loading={loading} size={15} />
          </DashSearchContainer>
        ) : (
          <span style={{ marginTop: 20, display: "flex" }}>
            <GlobalButton
              background={colors.primary}
              color={"white"}
              style={{
                margin: 0,
                marginRight: 20,
                borderRadius: 5,
                padding: "10px 20px",
                width: 100,
              }}
              type="button"
              onClick={() => setAddDues(false)}
            >
              Cancel
            </GlobalButton>
            <GlobalButton
              background={"green"}
              color={"white"}
              style={{
                margin: 0,
                borderRadius: 5,
                padding: "10px 20px",
                width: 100,
              }}
              type="submit"
              //   onClick={()=> action("APPROVE")}
            >
              Add
            </GlobalButton>
          </span>
        )}
      </form>
      <PopupView payView={change} setPayView={setChange}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <IoNotificationsCircleOutline
            color={colors.primary}
            style={{ minWidth: 300, marginBottom: 20, alignSelf: "center" }}
            size={30}
          />
          <h3 style={{ textAlign: "center", marginBottom: 30 }}>
            Membership Confirmation
          </h3>
          <p
            style={{
              textAlign: "center",
              color: colors.secondary,
              fontSize: 14,
            }}
          >
            To complete the membership process, an email with a membership
            invitation has been sent to{" "}
            <span style={{ fontWeight: "bold", margin: 0, marginLeft: 5 }}>
              {email}
            </span>
            .
          </p>
          <GlobalButton
            background={colors.primary}
            color="white"
            border={colors.primary}
            style={{
              width: "max-content",
              paddingLeft: 40,
              paddingRight: 40,
              alignSelf: "center",
              marginTop: 30,
            }}
            onClick={() => setChange(false)}
          >
            Okay
          </GlobalButton>
        </div>
      </PopupView>
      {/* <PendingFilterContainer onSubmit={(e) => search(e)}>
        <PendingSearch>
          <DashSearchInput
            placeholder="search dues by email"
            style={{ flex: 1, alignSelf: "center" }}
          />
          <GlobalButton
            background={colors.primary}
            color={"white"}
            style={{ margin: 0, borderRadius: 5, padding: "5px 10px" }}
            type="submit"
          >
            <RiSearchLine color={"white"} size={20} />
          </GlobalButton>
        </PendingSearch>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "max-content",
          }}
        >
          <DashSearchContainer
            style={{
              alignItems: "center",
              margin: 0,
              width: "max-content",
              marginRight: 5,
            }}
          >
            <DataListInput
              style={{
                height: "max-content",
                width: "max-content",
                padding: 10,
                fontSize: 12,
                border: "none",
                margin: 0,
                marginRight: 10,
              }}
            >
              <option value="" disabled selected>
                sort by
              </option>
              <option>Title</option>
              <option>Date ascending</option>
              <option>Date descending</option>
            </DataListInput>
          </DashSearchContainer>
          <DashSearchContainer
            style={{
              alignItems: "center",
              margin: 0,
              width: "max-content",
            }}
          >
            <DataListInput
              style={{
                height: "max-content",
                width: "max-content",
                padding: 10,
                fontSize: 12,
                border: "none",
                margin: 0,
                marginRight: 10,
              }}
            >
              <option value="" disabled selected>
                filter by
              </option>
              <option>All</option>
              <option>Lawyer</option>
              <option>Student</option>
            </DataListInput>
          </DashSearchContainer>
        </div>
      </PendingFilterContainer>
      <ul class="responsive-table">
        <li class="table-header">
          <div
            class="col col-d-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            Email
          </div>
          <div class="col col-d-2">Status</div>
          <div class="col col-d-3">Sent date</div>
          <div class="col col-d-4">Expiration date</div>
        </li>
        <MmanagementRow />
      </ul> */}
    </div>
  );
};

export default MemberManagement;
