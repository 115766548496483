import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { IoNotificationsCircleOutline } from "react-icons/io5";
import { ClipLoader, PropagateLoader } from "react-spinners";
import PopupView from "../../components/General/PopupPendingView";
import {
  AccessForm,
  AccessFormWidth,
  AccessInfo,
  DataListInput,
  FormInput,
} from "../../components/styles/Access";
import {
  DashSearchContainer,
  DashSearchInput,
} from "../../components/styles/Dashboard";
import Resizer from "react-image-file-resizer";
import { GlobalButton } from "../../components/styles/Global";
import { useExternalAPI } from "../../hooks/useExternalAPI";
import { colors } from "../../utils/colors";
import Select from "react-select";
import { FileUploader } from "react-drag-drop-files";
import { colourStyles } from "../../components/styles/Select2";
import { HiArrowNarrowRight } from "react-icons/hi";

const ReceivePayment = () => {
  const [addDues, setAddDues] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const { initAddMember } = useExternalAPI();
  const [error, setError] = useState("");
  const [change, setChange] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDuse, setUserDues] = useState([]);
  const [img, setImg] = useState(null);
  const [accountName, setAccountName] = useState(null);
  const [verify, setVerify] = useState(false);
  const [phone, setPhone] = useState("");
  const [paymentType, setTypePayment] = useState(null);
  const [base64, setBase64] = useState(null);
  const [file, setFile] = useState(null);
  const { getMembers, fetchUserDues } = useExternalAPI();
  const formRef = useRef();
  const fileTypes = ["JPG", "PNG"];

  const search = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchMembers("APRUSER");
  }, []);

  useEffect(() => {
    // console.log("Selected: ", selectedUser)
    if (selectedUser !== null) getUserDues(selectedUser?.value);
  }, [selectedUser]);

  const fetchMembers = async (searchmode) => {
    if (loading) return;
    setError("");
    setLoading(true);

    const data = {
      searchmode,
    };

    const request = await getMembers(data);

    if (request?.success === true) {
      setUsers([]);
      request?.data?.data.forEach((e) => {
        setUsers((users) => [
          ...users,
          {
            label: e.firstname + " " + e.lastname + ` (${e.emailaddress})`,
            value: e.id,
          },
        ]);
      });
    }

    setLoading(false);
  };

  const getUserDues = async (id) => {
    fetchUserDues({
      userid: id,
    })
      .then((res) => console.log("Dues: ", res))
      .catch((e) => console.error(e));
  };

  const handleChange = async (file) => {
    // console.log("File: ", file);
    setImg(URL.createObjectURL(file));
    // const converted = handleCompressedUpload(file);
    const resize = await resizeFile(file);
    // console.log("Size before: ", file?.size);
    setFile(resize);
    // console.log(getBase64());
    // console.log("Size after: ", resize?.size)
    setBase64(resize);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        400,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h4
        style={{
          margin: "15px 0",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AccessInfo style={{ margin: "0 20px 0 0" }}>
          Receive Payment
        </AccessInfo>
      </h4>
      <AccessFormWidth
        style={{ fontSize: 14, color: "grey" }}
        // onSubmit={(e) => addMember(e)}
        ref={formRef}
      >
        Account Type *
        <DataListInput style={{ marginBottom: 20 }} required>
          <option value="" disabled selected>
            select account type
          </option>
          <option>LAWYER</option>
          <option>STUDENT</option>
        </DataListInput>
        User account *
        <Select
          closeMenuOnSelect={false}
          // components={animatedComponents}
          styles={colourStyles}
          options={users}
          onChange={(e) => setSelectedUser(e)}
        />
        Dues unpaid *
        <Select
          closeMenuOnSelect={false}
          // components={animatedComponents}
          styles={colourStyles}
          options={[]}
          //   onChange={(e) => setSelectedUser(e)}
        />
        Choose Payment Method *
        <DataListInput
          style={{ marginBottom: 10 }}
          onChange={(e) => setTypePayment(e.target.value)}
          required
          name="paymentmode"
        >
          <option value="" disabled selected>
            select type
          </option>
          <option value="MOMO">Mobile Money</option>
          <option value="BANK">Bank Deposit</option>
        </DataListInput>
        {paymentType === "MOMO" ? (
          <>
            Choose Mobile Wallet *
            <DataListInput
              style={{ marginBottom: 10 }}
              onChange={(e) => null} //console.log(e.target.value)}
              required
            >
              <option>MTN</option>
              <option>Vodafone</option>
              <option>AirtelTigo</option>
            </DataListInput>
            Phone number *
            <FormInput
              type="tel"
              required
              placeholder="input number"
              hidden={false}
              onChange={(e) => setPhone(e.target.value)}
            />
            {accountName !== null ? (
              <>
                <p
                  style={{
                    color: "black",
                    marginBottom: 25,
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    borderRadius: 5,
                    padding: "5px 7px",
                    marginTop: 10,
                  }}
                >
                  Please verify if
                  <h2
                    style={{ color: "black", display: "inline", fontSize: 17 }}
                  >
                    {" "}
                    {accountName}{" "}
                  </h2>
                  is the name on the account entered above.
                  <div
                    style={{
                      display: "flex",
                      fontWeight: "200",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={"checkbox"}
                      style={{ marginRight: 10 }}
                      onChange={(e) => setVerify(e.target.checked)}
                      id={"verify"}
                    />{" "}
                    <label for="verify">I verify</label>
                  </div>
                </p>
              </>
            ) : null}
          </>
        ) : paymentType === "BANK" ? (
          <>
            <p style={{ marginBottom: 10 }}>Upload bank transaction receipt</p>
            <FileUploader
              handleChange={handleChange}
              name="file"
              label="Upload or drag and drop payment receipt here."
              types={fileTypes}
              multiple={false}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              {img !== null ? (
                <img src={img} alt="" style={{ height: 400, width: "auto" }} />
              ) : null}
            </div>
          </>
        ) : null}
        <p style={{ fontSize: 12, color: "red", marginTop: 5 }}>{error}</p>
        {loading ? (
          <DashSearchContainer
            style={{
              margin: "10px 0",
              padding: 10,
              alignItems: "center",
              color: "black",
            }}
          >
            Please wait while form data loads..
            <span style={{ flex: 1 }} />
            <ClipLoader color={colors.primary} loading={loading} size={15} />
          </DashSearchContainer>
        ) : (
          <GlobalButton
            background={colors.primary}
            color="white"
            border={colors.primary}
            style={{ marginTop: 25 }}
            type="submit"
          >
            {loading ? (
              <span style={{ padding: 10, marginTop: -10, marginBottom: 7 }}>
                <PropagateLoader color={"white"} loading={loading} size={15} />
              </span>
            ) : (
              <>
                {verify ? "Pay" : "Continue"}{" "}
                <HiArrowNarrowRight
                  size={15}
                  color="white"
                  style={{ marginLeft: 10 }}
                />
              </>
            )}
          </GlobalButton>
        )}
      </AccessFormWidth>
      <PopupView payView={change} setPayView={setChange}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <IoNotificationsCircleOutline
            color={colors.primary}
            style={{ minWidth: 300, marginBottom: 20, alignSelf: "center" }}
            size={30}
          />
          <h3 style={{ textAlign: "center", marginBottom: 30 }}>
            Membership Confirmation
          </h3>
          <p
            style={{
              textAlign: "center",
              color: colors.secondary,
              fontSize: 14,
            }}
          >
            To complete the membership process, an email with a membership
            invitation has been sent to{" "}
            <span style={{ fontWeight: "bold", margin: 0, marginLeft: 5 }}>
              {email}
            </span>
            .
          </p>
          <GlobalButton
            background={colors.primary}
            color="white"
            border={colors.primary}
            style={{
              width: "max-content",
              paddingLeft: 40,
              paddingRight: 40,
              alignSelf: "center",
              marginTop: 30,
            }}
            onClick={() => setChange(false)}
          >
            Okay
          </GlobalButton>
        </div>
      </PopupView>
    </div>
  );
};

export default ReceivePayment;
