import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { useAuth } from "../../context/AuthProvider";
import {
  DashboardSelectSection,
  DashSearchContainer,
  DashSearchInput,
  IconDashRight,
} from "../styles/Dashboard";
import { RowDivSpace } from "../styles/Global";
import { pages } from "../../utils/pages";
import DropList from "../Navigation/DropList";

const MenuButtons = ({ navOpen, page, setNavOpen }) => {
  const { navigate } = useAuth();
  const [pagename, setPagename] = useState("");

  useEffect(() => {
    console.log("Url: ", page);
    pages?.forEach((element) => {
      if (element?.path === page) setPagename(element?.name);
    });
  }, [page]);

  const direct = (url) => {
    setNavOpen(false);
    navigate(url);
  };

  return (
    <DashboardSelectSection isOpen={navOpen} >
      <h1 style={{ fontSize: 16, textTransform: "capitalize" }}>
        {pagename || page}
      </h1>
      <DashSearchContainer>
        <RiSearchLine color={"black"} size={20} />
        <DashSearchInput placeholder="search.." />
      </DashSearchContainer>
      <DropList title="Dashboard">
        <>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard")}
          >
            Dashboard
          </RowDivSpace>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/reports")}
          >
            Reports
          </RowDivSpace>
        </>
      </DropList>
      {/* <RowDivSpace
        style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
        onClick={() => direct("/dashboard/pendingregistration")}
      >
        Pending Registration
      </RowDivSpace> */}
      <DropList title="Pending Registration">
        <>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/paidregistration")}
          >
            Paid
          </RowDivSpace>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/pendingregistration")}
          >
            Unpaid
          </RowDivSpace>
        </>
      </DropList>
      <DropList title="Member Management">
        <>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/membermanagement")}
          >
            Add member
          </RowDivSpace>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/dues")}
          >
            Set up dues
          </RowDivSpace>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/paymenthistory")}
          >
            Dues history
          </RowDivSpace>
        </>
      </DropList>
      <RowDivSpace
        style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
        onClick={() => direct("/dashboard/memberdirectories")}
      >
        Member Directories
      </RowDivSpace>
      <RowDivSpace
        style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
        onClick={() => direct("/dashboard/communication")}
      >
        Communication
        <IconDashRight />
      </RowDivSpace>
      <RowDivSpace style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}>
        Event Managament
      </RowDivSpace>
      <RowDivSpace style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}>
        Journal Management
      </RowDivSpace>
      {/* <RowDivSpace
        style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
        onClick={() => direct("/dashboard/dues")}
      >
        Dues
      </RowDivSpace> */}
      <DropList title="Receipts & Payments">
        <>
          <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/receivepayment")}
          >
            Receive Payment
          </RowDivSpace>
          {/* <RowDivSpace
            style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}
            onClick={() => direct("/dashboard/dues")}
          >
            Payment
          </RowDivSpace> */}
        </>
      </DropList>
      <RowDivSpace style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}>
        Fundraising
      </RowDivSpace>
      <RowDivSpace style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }}>
        Report and Analysis
      </RowDivSpace>
      <RowDivSpace style={{ fontSize: 14, marginTop: 20, cursor: "pointer" }} onClick={() => direct("/dashboard/settings")}>
        Settings
      </RowDivSpace>
    </DashboardSelectSection>
  );
};

export default MenuButtons;
