import React, { useEffect, useState } from "react";
import { AccessInfo, DataListInput } from "../../components/styles/Access";
import { useExternalAPI } from "../../hooks/useExternalAPI";
import "../../components/styles/PendingRegistrations.scss";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { MdError } from "react-icons/md";
import PendingRow from "../../components/Pending/PendingRow";
import {
  DashSearchContainer,
  DashSearchInput,
} from "../../components/styles/Dashboard";
import { RiSearchLine } from "react-icons/ri";
import {
  PendingFilterContainer,
  PendingSearch,
} from "../../components/styles/PendingRegistrations";
import { GlobalButton } from "../../components/styles/Global";
import { colors } from "../../utils/colors";
import { ClipLoader } from "react-spinners";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import DataFooter from "../../components/General/DataFooter";
import Modal from "react-responsive-modal";

const PendingRegistration = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const { getPendingAccounts, chequeUpdate } = useExternalAPI();
  const [searchName, setSearchName] = useState("");
  const [page, setPage] = useState("");
  const [pages, setPages] = useState([]);
  const [checkDisplay, setCheckDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pendingList, setPendingList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filteredList, setFilteredList] = useState(null);
  const [totalPages, setTotalPages] = useState([]);
  const [viewCheque, setViewCheque] = useState(false);
  const [cheque, setCheque] = useState("");
  const [chequeLoad, setChequeLoad] = useState(false);

  useEffect(() => {
    const search = searchParams?.get("search") || "";
    const page = searchParams?.get("page") || "";
    // console.log("Search: ", search)
    setSearchName(search);
    setPage(page);
    fetchPending(search, page);
  }, []);

  const filter = (sort, type) => {
    if (sort === "" || sort === "Date ascending") {
      filteredList !== null
        ? setFilteredList((e) =>
            e.sort((a, b) => new Date(a.datecreated) - new Date(b.datecreated))
          )
        : setPendingList((e) =>
            e.sort((a, b) => new Date(a.datecreated) - new Date(b.datecreated))
          );
    } else if (sort === "Account name") {
      filteredList !== null
        ? setFilteredList((e) => e.sort((a, b) => a.lastname - b.lastname))
        : setPendingList((e) => e.sort((a, b) => a.lastname - b.lastname));
    } else if (sort === "Account email") {
      filteredList !== null
        ? setFilteredList((e) =>
            e.sort((a, b) => a.emailaddress - b.emailaddress)
          )
        : setPendingList((e) =>
            e.sort((a, b) => a.emailaddress - b.emailaddress)
          );
    } else {
      filteredList !== null
        ? setFilteredList((e) =>
            e.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated))
          )
        : setPendingList((e) =>
            e.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated))
          );
    }
  };

  const search = (e) => {
    e.preventDefault();

    const searchName = e.target[0].value;

    fetchPending(searchName || "", "1");

    // const sort = e.target[1].value;
    // setFilteredList(null);

    // console.log(search, sort);
    // if (pendingList.length > 0) {
    //   setFilteredList(pendingList);
    //   console.log("List yeah");
    //   if (search?.length > 0) {
    //     console.log("Search yeah");
    //     setFilteredList(
    //       pendingList.filter((value) =>
    //         value.emailaddress.toLowerCase().match(new RegExp(search, "g"))
    //       )
    //     );
    //   }
    // }
  };

  const fetchPending = async (searchtext, page) => {
    if (loading) return;
    setError("");
    setLoading(true);

    const data = {
      searchtext,
      page,
      pendingtypes: "INCOMPLETE_PAYMENT",
      ishavingbankpaymentdetails: true,
    };

    const request = await getPendingAccounts(data);
    if (request?.success === true) {
      setPendingList(request?.data?.data);
      setTotalPages(request?.data?.totalpages);
      const pagesList = [];
      setPages([]);
      for (let i = 1; i <= request?.data?.totalpages; i++) {
        pagesList.push({
          label: `Page ${i + " / " + request?.data?.totalpages}`,
          selected: i === request?.data?.currentpage,
          page: i.toString(),
        });
      }
      setPages(pagesList);
      // console.log("PagesList: ", pagesList + " / " + request?.data?.totalpages);
    } else {
      setError("An error occured, try again later.");
    }

    setLoading(false);
  };

  const movePage = (next) => {
    // console.log("Page: ", page);
    const position = (
      next ? parseInt(page) + 1 || 1 + 1 : parseInt(page) - 1
    ).toString();
    // window.history.replaceState(
    //   null,
    //   "",
    //   "https://localhost:3000/dashboard/paymenthistory"
    // );
    setPage(position);
    fetchPending(searchName, position);
  };

  const direct = (page) => {
    // const position = next ? parseInt(page) + 1 : parseInt(page) - 1;
    // window.history.replaceState(
    //   null,
    //   "",
    //   "https://localhost:3000/dashboard/pendingregistration"
    // );
    setPage(page);
    fetchPending(searchName, page);
  };

  const removeUser = (id) => {
    if (pendingList !== [])
      setPendingList((e) => e?.filter((user) => user.id !== id));
    if (filteredList !== null)
      setFilteredList((e) => e?.filter((user) => user.id !== id));
  };

  const chequeAction = async (action) => {
    setChequeLoad(true);

    const data = {
      ...cheque,
      requeststatus: action,
    };

    const res = await chequeUpdate(data);
    if (res?.success === true && action === "APPROVE") {
      removeUser(cheque?.id);
    }

    setViewCheque(false);
    setChequeLoad(false);
  };

  return (
    <div>
      <Modal open={viewCheque} onClose={() => setViewCheque(false)}>
        <div style={{ marginTop: 30 }}>
          <img
            src={cheque?.paymentdetails}
            style={{ widht: "100%", marginBottom: 10 }}
          />
          {chequeLoad ? (
            "...please wait"
          ) : (
            <span style={{ display: "flex" }}>
              <GlobalButton
                background={colors.primary}
                color={"white"}
                style={{
                  margin: 0,
                  borderRadius: 5,
                  padding: "10px 20px",
                  width: "max-content",
                  marginRight: 10,
                }}
                type="submit"
                onClick={() => chequeAction("REJECT")}
              >
                Reject
              </GlobalButton>
              <GlobalButton
                background={"green"}
                color={"white"}
                style={{
                  margin: 0,
                  borderRadius: 5,
                  padding: "10px 20px",
                  width: "max-content",
                }}
                type="submit"
                onClick={() => chequeAction("APPROVE")}
              >
                Approve
              </GlobalButton>
            </span>
          )}
        </div>
      </Modal>
      {loading ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.09)",
          }}
        >
          Loading..
          <ClipLoader color={colors.primary} loading={loading} size={15} />
        </DashSearchContainer>
      ) : error !== "" ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#E6505C",
            border: "none",
            color: "white",
          }}
        >
          {error}
          <MdError color={"white"} size={20} />
        </DashSearchContainer>
      ) : (pendingList === [] ||
          pendingList === null ||
          pendingList.length === 0) &&
        searchName === "" &&
        page === "" ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.09)",
            backgroundColor: "#f8f8fa",
            fontSize: 14,
          }}
        >
          Currently don't have any pending approvals
          <IoNotificationsCircleSharp color={colors.primary} size={30} />
        </DashSearchContainer>
      ) : (
        <>
          <PendingFilterContainer onSubmit={(e) => search(e)}>
            <PendingSearch>
              <DashSearchInput
                placeholder="search account by email.."
                style={{ flex: 1, alignSelf: "center" }}
                onChange={(e) => setSearchName(e.target.value)}
              />
              <GlobalButton
                background={colors.primary}
                color={"white"}
                style={{ margin: 0, borderRadius: 5, padding: "5px 10px" }}
                type="submit"
              >
                <RiSearchLine color={"white"} size={20} />
              </GlobalButton>
            </PendingSearch>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "max-content",
              }}
            >
              <DashSearchContainer
                style={{
                  alignItems: "center",
                  margin: 0,
                  width: "max-content",
                  marginRight: 5,
                }}
              >
                <DataListInput
                  style={{
                    height: "max-content",
                    width: "max-content",
                    padding: 10,
                    fontSize: 12,
                    border: "none",
                    margin: 0,
                    marginRight: 10,
                  }}
                >
                  <option value="" disabled selected>
                    sort by
                  </option>
                  <option>Account name</option>
                  <option>Account email</option>
                  <option>Date ascending</option>
                  <option>Date descending</option>
                </DataListInput>
              </DashSearchContainer>
              <DashSearchContainer
                style={{
                  alignItems: "center",
                  margin: 0,
                  width: "max-content",
                }}
              >
                <DataListInput
                  style={{
                    height: "max-content",
                    width: "max-content",
                    padding: 10,
                    fontSize: 12,
                    border: "none",
                    margin: 0,
                    marginRight: 10,
                  }}
                >
                  <option value="" disabled selected>
                    filter by
                  </option>
                  <option>All</option>
                  <option>Lawyer</option>
                  <option>Student</option>
                </DataListInput>
              </DashSearchContainer>
            </div>
          </PendingFilterContainer>
          <ul class="responsive-table">
            <li class="table-header">
              <div
                class="col col-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label class="container">
                  <input
                    type={"checkbox"}
                    style={{ marginRight: 5 }}
                    onChange={(e) => setCheckDisplay(e.target.checked)}
                  />
                  <span class="checkmark" />
                </label>
                Full name
              </div>
              <div class="col col-2">Gender</div>
              <div class="col col-3">Email</div>
              <div class="col col-4">Verified</div>
              <div class="col col-5">Phone</div>
              <div class="col col-6">Account</div>
              <div class="col col-7">Joined</div>
            </li>
            {pendingList.length === 0 ? (
              <DashSearchContainer
                style={{
                  margin: "10px 0",
                  padding: 10,
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, 0.09)",
                  backgroundColor: "#f8f8fa",
                  fontSize: 14,
                }}
              >
                No data record found with search name{" "}
                <span style={{ fontWeight: "bold", margin: "0 5px" }}>
                  {searchName}
                </span>{" "}
                and page .
                <span style={{ fontWeight: "bold", margin: "0 5px" }}>
                  {page === "" ? "1" : page}
                </span>
                <span
                  style={{
                    textDecoration: "underline",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPage("1");
                    setSearchName("");
                    fetchPending("", "");
                  }}
                >
                  Go back
                </span>
                <div style={{ flex: 1 }} />
                <IoNotificationsCircleSharp color={colors.primary} size={30} />
              </DashSearchContainer>
            ) : (
              <>
                {filteredList !== null
                  ? filteredList.map((data, index) => (
                      <PendingRow
                        key={data.id}
                        checkDisplay={checkDisplay}
                        data={data}
                        removeUser={removeUser}
                        unpaid={true}
                        setCheque={setCheque}
                        setViewCheque={setViewCheque}
                      />
                    ))
                  : pendingList.map((data, index) => (
                      <PendingRow
                        key={data.id}
                        checkDisplay={checkDisplay}
                        data={data}
                        removeUser={removeUser}
                        unpaid={true}
                        setCheque={setCheque}
                        setViewCheque={setViewCheque}
                      />
                    ))}
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GlobalButton
                    background={colors.primary}
                    color={"white"}
                    style={{
                      margin: 0,
                      borderRadius: 5,
                      padding: "5px 10px",
                      marginRight: 5,
                    }}
                    type="submit"
                  >
                    <FiChevronLeft color="white" />
                  </GlobalButton>
                  <DataListInput
                  style={{
                    height: "max-content",
                    width: "max-content",
                    padding: 10,
                    fontSize: 12,
                    border: "none",
                    margin: 0,
                    marginRight: 10,
                  }}
                >
                  {
                    pages.map((data, index)=> 
                    <option value="" key={index} selected={data?.selected}>
                    {data?.label}
                  </option>)
                  }
                </DataListInput>
                  <GlobalButton
                    background={colors.primary}
                    color={"white"}
                    style={{
                      margin: 0,
                      borderRadius: 5,
                      padding: "5px 10px",
                      marginLeft: 5,
                    }}
                    type="submit"
                  >
                    <FiChevronRight color="white" />
                  </GlobalButton>
                </div> */}
                <DataFooter
                  pages={pages}
                  page={page === "" ? "1" : page}
                  load={movePage}
                  direct={direct}
                />
              </>
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default PendingRegistration;
