import React, { useEffect, useState } from "react";
import { AccessInfo, DataListInput } from "../../components/styles/Access";
import { useExternalAPI } from "../../hooks/useExternalAPI";
import "../../components/styles/PendingRegistrations.scss";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { MdError } from "react-icons/md";
import {
  DashSearchContainer,
  DashSearchInput,
} from "../../components/styles/Dashboard";
import { RiSearchLine } from "react-icons/ri";
import {
  PendingFilterContainer,
  PendingSearch,
} from "../../components/styles/PendingRegistrations";
import { GlobalButton } from "../../components/styles/Global";
import { colors } from "../../utils/colors";
import { ClipLoader } from "react-spinners";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import HistoryRow from "../../components/PaymentHistory/HistoryRow";
import { useSearchParams } from "react-router-dom";
import DataFooter from "../../components/General/DataFooter";

const PaymentHistory = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const { getPendingAccounts, fetchPaymentHistory } = useExternalAPI();
  const [checkDisplay, setCheckDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pendingList, setPendingList] = useState([]);
  const [filteredList, setFilteredList] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [page, setPage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const search = searchParams?.get("search") || "";
    const page = searchParams?.get("page") || "";
    // console.log("Search: ", search)
    setSearchName(search);
    setPage(page);
    fetchPending(search, page);
  }, []);

  const filter = (sort, type) => {
    if (sort === "" || sort === "Date ascending") {
      filteredList !== null
        ? setFilteredList((e) =>
            e.sort((a, b) => new Date(a.datecreated) - new Date(b.datecreated))
          )
        : setPendingList((e) =>
            e.sort((a, b) => new Date(a.datecreated) - new Date(b.datecreated))
          );
    } else if (sort === "Account name") {
      filteredList !== null
        ? setFilteredList((e) => e.sort((a, b) => a.lastname - b.lastname))
        : setPendingList((e) => e.sort((a, b) => a.lastname - b.lastname));
    } else if (sort === "Account email") {
      filteredList !== null
        ? setFilteredList((e) =>
            e.sort((a, b) => a.emailaddress - b.emailaddress)
          )
        : setPendingList((e) =>
            e.sort((a, b) => a.emailaddress - b.emailaddress)
          );
    } else {
      filteredList !== null
        ? setFilteredList((e) =>
            e.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated))
          )
        : setPendingList((e) =>
            e.sort((a, b) => new Date(b.datecreated) - new Date(a.datecreated))
          );
    }
  };

  const search = (e) => {
    e.preventDefault();

    const searchName = e.target[0].value;

    fetchPending(searchName || "", "1");
  };

  const fetchPending = async (searchtext, page) => {
    if (loading) return;
    setError("");
    setLoading(true);

    const data = {
      paymentmode: "",
      paymentstatus: "PAID",
      page,
    };

    const request = await fetchPaymentHistory(data);
    if (request?.success === true) {
      setPendingList(request?.data?.data);
      setTotalPages(request?.data?.totalpages);
      const pagesList = [];
      setPages([]);
      for (let i = 1; i <= request?.data?.totalpages; i++) {
        pagesList.push({
          label: `Page ${i + " / " + request?.data?.totalpages}`,
          selected: i === request?.data?.currentpage,
          page: i.toString(),
        });
      }
      setPages(pagesList);
      // console.log("PagesList: ", pagesList + " / " + request?.data?.totalpages);
    } else {
      setError("An error occured, try again later.");
    }

    setLoading(false);
  };

  const removeUser = (id) => {
    if (pendingList !== [])
      setPendingList((e) => e?.filter((user) => user.id !== id));
    if (filteredList !== null)
      setFilteredList((e) => e?.filter((user) => user.id !== id));
  };

  const movePage = (next) => {
    const position = (
      next ? parseInt(page) + 1 || 1 + 1 : parseInt(page) - 1
    ).toString();
    // window.history.replaceState(
    //   null,
    //   "",
    //   "https://localhost:3000/dashboard/paymenthistory"
    // );
    setPage(position);
    fetchPending(searchName, position);
  };

  const direct = (page) => {
    // const position = next ? parseInt(page) + 1 : parseInt(page) - 1;
    // window.history.replaceState(
    //   null,
    //   "",
    //   "https://localhost:3000/dashboard/pendingregistration"
    // );
    setPage(page);
    fetchPending(searchName, page);
  };

  return (
    <div>
      {loading ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.09)",
          }}
        >
          Loading..
          <ClipLoader color={colors.primary} loading={loading} size={15} />
        </DashSearchContainer>
      ) : error !== "" ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#E6505C",
            border: "none",
            color: "white",
          }}
        >
          {error}
          <MdError color={"white"} size={20} />
        </DashSearchContainer>
      ) : pendingList === [] ||
        pendingList === null ||
        (pendingList?.length === 0 && searchName === "" && page === "") ? (
        <DashSearchContainer
          style={{
            margin: "10px 0",
            padding: 10,
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.09)",
            backgroundColor: "#f8f8fa",
            fontSize: 14,
          }}
        >
          Currently don't have any payment histories.
          <IoNotificationsCircleSharp color={colors.primary} size={30} />
        </DashSearchContainer>
      ) : (
        <>
          <PendingFilterContainer onSubmit={(e) => search(e)}>
            {/* <PendingSearch>
              <DashSearchInput
                placeholder="search account by email.."
                style={{ flex: 1, alignSelf: "center" }}
              />
              <GlobalButton
                background={colors.primary}
                color={"white"}
                style={{ margin: 0, borderRadius: 5, padding: "5px 10px" }}
                type="submit"
              >
                <RiSearchLine color={"white"} size={20} />
              </GlobalButton>
            </PendingSearch> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "max-content",
              }}
            >
              <DashSearchContainer
                style={{
                  alignItems: "center",
                  margin: 0,
                  width: "max-content",
                  marginRight: 5,
                }}
              >
                <DataListInput
                  style={{
                    height: "max-content",
                    width: "max-content",
                    padding: 10,
                    fontSize: 12,
                    border: "none",
                    margin: 0,
                    marginRight: 10,
                  }}
                >
                  <option value="" disabled selected>
                    sort by
                  </option>
                  <option>Account name</option>
                  <option>Account email</option>
                  <option>Date ascending</option>
                  <option>Date descending</option>
                </DataListInput>
              </DashSearchContainer>
              <DashSearchContainer
                style={{
                  alignItems: "center",
                  margin: 0,
                  width: "max-content",
                }}
              >
                <DataListInput
                  style={{
                    height: "max-content",
                    width: "max-content",
                    padding: 10,
                    fontSize: 12,
                    border: "none",
                    margin: 0,
                    marginRight: 10,
                  }}
                >
                  <option value="" disabled selected>
                    filter by
                  </option>
                  <option>All</option>
                  <option>Lawyer</option>
                  <option>Student</option>
                </DataListInput>
              </DashSearchContainer>
            </div>
          </PendingFilterContainer>
          <ul class="responsive-table">
            <li class="table-header">
              <div
                class="col col-d-1"
                style={{ display: "flex", alignItems: "center" }}
              >
                Email
              </div>
              <div class="col col-d-2">Amount</div>
              <div class="col col-d-3">Method</div>
              <div class="col col-d-4">Date Paid</div>
            </li>
            {pendingList.length === 0 ? (
              <DashSearchContainer
                style={{
                  margin: "10px 0",
                  padding: 10,
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, 0.09)",
                  backgroundColor: "#f8f8fa",
                  fontSize: 14,
                }}
              >
                No data record found with search name "
                <span style={{ fontWeight: "bold", margin: "0 5px" }}>
                  {searchName}
                </span>{" "}
                " and page .
                <span style={{ fontWeight: "bold", margin: "0 5px" }}>
                  {page === "" ? "1" : page}
                </span>
                <span
                  style={{
                    textDecoration: "underline",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPage("1");
                    setSearchName("");
                    fetchPending("", "");
                  }}
                >
                  Go back
                </span>
                <div style={{ flex: 1 }} />
                <IoNotificationsCircleSharp color={colors.primary} size={30} />
              </DashSearchContainer>
            ) : filteredList !== null ? (
              filteredList.map((data, index) => (
                <HistoryRow
                  key={data.id}
                  checkDisplay={checkDisplay}
                  data={data}
                  removeUser={removeUser}
                  unpaid={true}
                />
              ))
            ) : (
              pendingList.map((data, index) => (
                <HistoryRow
                  key={data.id}
                  checkDisplay={checkDisplay}
                  data={data}
                  removeUser={removeUser}
                  unpaid={true}
                />
              ))
            )}
          </ul>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GlobalButton
              background={colors.primary}
              color={"white"}
              style={{
                margin: 0,
                borderRadius: 5,
                padding: "5px 10px",
                marginRight: 5,
              }}
              type="submit"
            >
              <FiChevronLeft color="white" />
            </GlobalButton>
            <span
              style={{
                padding: 5,
                borderRadius: 2,
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                width: 30,
                textAlign: "center",
              }}
            >
              1
            </span>
            <GlobalButton
              background={colors.primary}
              color={"white"}
              style={{
                margin: 0,
                borderRadius: 5,
                padding: "5px 10px",
                marginLeft: 5,
              }}
              type="submit"
            >
              <FiChevronRight color="white" />
            </GlobalButton>
          </div> */}
          <DataFooter
            pages={pages}
            page={page === "" ? "1" : page}
            load={movePage}
            direct={direct}
          />
        </>
      )}
    </div>
  );
};

export default PaymentHistory;
