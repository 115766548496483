import React, { useEffect, useState } from "react";
import Navigation from "../components/Dashboard/Navigation";
import { RowDivSpace, RowSpan } from "../components/styles/Global";
import dateFormat from "dateformat";
import {
  DashboardContainer,
  DashboardWorkSection,
  DashDate,
  IconDashNotification,
  IconDashProfile,
} from "../components/styles/Dashboard";
import { useAuth } from "../context/AuthProvider";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Dashboard/Home";
import DashNotFound from "./Dashboard/DashNotFound";
import MenuButtons from "../components/Dashboard/MenuButtons";
import PendingRegistration from "./Dashboard/PendingRegistration";
import Communication from "./Dashboard/Communication";
import Dues from "./Dashboard/Dues";
import MemberManagement from "./Dashboard/MemberManagement";
import MemberDirectories from "./Dashboard/MemberDirectories";
import Receipts from "./Dashboard/Receipts";
import PaindPendingRegistrations from "./Dashboard/PaindPendingRegistrations";
import Settings from "./Dashboard/Settings";
import PaymentHistory from "./Dashboard/PaymentHistory";
import ReceivePayment from "./Dashboard/ReceivePayment";

const Dashboard = () => {
  const [navOpen, setNavOpen] = useState(false);
  const { logout, user, navigate } = useAuth();
  const [page, setPage] = useState("");
  const location = useLocation();

  useEffect(() => {
    try {
      const pages = location.pathname?.split("/");
      setPage(pages.length === 3 ? pages[2] : pages[1]);
    } catch (e) {
      console.error(e);
    }
  }, [location]);

  return (
    <DashboardContainer>
      <Navigation setNavOpen={setNavOpen} page={page}/>
      <MenuButtons navOpen={navOpen} page={page} setNavOpen={setNavOpen}/>
      <DashboardWorkSection>
        <RowDivSpace
          style={{
            fontSize: 14,
            alignItems: "center",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            paddingBottom: 10
          }}
        >
          <div>
            {/* <h3 style={{ textTransform: "capitalize" }}>{page}</h3> */}
            <DashDate>{dateFormat(Date.now(), "dddd, dS mmmm")}</DashDate>
          </div>
          <RowSpan>
            <IconDashNotification />
            <IconDashProfile onClick={() => navigate("dashboard/profile")} />
          </RowSpan>
        </RowDivSpace>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pendingregistration" element={<PendingRegistration />} />
          <Route path="/paidregistration" element={<PaindPendingRegistrations />} />
          <Route path="/communication" element={<Communication />} />
          <Route path="/dues" element={<Dues />} />
          <Route path="/receipts" element={<Receipts />} />
          <Route path="/memberdirectories" element={<MemberDirectories />} />
          <Route path="/membermanagement" element={<MemberManagement />} />
          <Route path="/paymenthistory" element={<PaymentHistory />} />
          <Route path="/receivepayment" element={<ReceivePayment />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<DashNotFound />} />
        </Routes>
      </DashboardWorkSection>
    </DashboardContainer>
  );
};

export default Dashboard;
