import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { colors } from "../../utils/colors";
import { DataListInput } from "../styles/Access";
import { GlobalButton } from "../styles/Global";

const DataFooter = ({ pages, page, load, direct }) => {
  // console.log("Page: ", page+" / "+pages[0])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {page === pages[0]?.page ? null : (
        <GlobalButton
          background={colors.primary}
          color={"white"}
          style={{
            margin: 0,
            borderRadius: 5,
            padding: "5px 10px",
            marginRight: 5,
          }}
          // type="submit"
          onClick={() => load(false)}
        >
          <FiChevronLeft color="white" />
        </GlobalButton>
      )}
      <DataListInput
        style={{
          height: "max-content",
          width: "max-content",
          padding: 10,
          fontSize: 12,
          border: "none",
          margin: 0,
          marginRight: 10,
        }}
        onChange={(e) => console.log("Page: ", direct(e.target.value))}
      >
        {pages.map((data, index) => (
          <option value={data?.page} key={index} selected={data?.selected}>
            {data?.label}
          </option>
        ))}
      </DataListInput>
      {page === pages[pages.length - 1]?.page ? null : (
        <GlobalButton
          background={colors.primary}
          color={"white"}
          style={{
            margin: 0,
            borderRadius: 5,
            padding: "5px 10px",
            marginLeft: 5,
          }}
          // type="submit"
          onClick={() => load(true)}
        >
          <FiChevronRight color="white" />
        </GlobalButton>
      )}
    </div>
  );
};

export default DataFooter;
