import React, { useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import PopupView from "../../components/General/PopupPendingView";
import ChangePassword from "../../components/Settings/ChangePassword";
import { GlobalDashButton } from "../../components/styles/Global";
import {
  ProfileCoverContainer,
  ProfileCoverImg,
  ProfileDivLeft,
  ProfileIfoContainer,
  ProfileImgUser,
  ProfileImgUserDefault,
  ProfileInfoSub,
  ProfileName,
  ProfileType,
} from "../../components/styles/Profile";
import { useAuth } from "../../context/AuthProvider";
import { colors } from "../../utils/colors";

const Settings = () => {
  const { user, navigate } = useAuth();
  const { firstname, lastname, profilepicture, emailaddress } = user;
  const profileUrl = profilepicture;
  const [change, setChange] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <ProfileDivLeft>
        <button
          onClick={() => navigate(-1)}
          style={{
            fontSize: 12,
            color: colors.primary,
            background: "none",
            border: "none",
            cursor: "pointer",
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
          }}
          type="button"
        >
          <MdOutlineKeyboardBackspace
            size={15}
            color={colors.primary}
            style={{ marginRight: 5 }}
          />{" "}
          back
        </button>
        <ProfileCoverContainer style={{ width: "100%" }}>
          <ProfileCoverImg src={require("../../assets/coverimg.jpeg")} />
          {/* <div
          style={{
            position: "absolute",
            top: 0,
            backgroundColor: "rgba(256,256,256,0.5)",
            display: "flex",
            right: 0,
            justifyContent: "center",
            padding: 5,
            borderRadius: "0 0 0 10px",
            cursor: "pointer",
            alignItems: "center",
          }}
        >
          change cover photo{" "}
          <IoMdReverseCamera
            size={30}
            color={colors.primary}
            style={{ marginLeft: 5 }}
          />
        </div> */}
          <ProfileIfoContainer>
            <div
              style={{
                position: "relative",
                width: "max-content",
              }}
            >
              {profileUrl === null || profileUrl === undefined ? (
                <ProfileImgUserDefault color="grey" />
              ) : (
                <ProfileImgUser src={profileUrl} />
              )}
            </div>
            <ProfileInfoSub>
              <ProfileName>{lastname + " " + firstname}</ProfileName>
              <ProfileType>{emailaddress}</ProfileType>
            </ProfileInfoSub>
            <GlobalDashButton
              background={colors.primary}
              color="white"
              style={{ marginRight: 10, alignItems: "center" }}
              border={colors.primary}
              onClick={() => setChange(true)}
            >
              Change password
            </GlobalDashButton>
          </ProfileIfoContainer>
        </ProfileCoverContainer>
      </ProfileDivLeft>
      <PopupView payView={change} setPayView={setChange}>
        <ChangePassword setChange={setChange} />
      </PopupView>
    </div>
  );
};

export default Settings;
