export const pages = [
  { path: "dashboard", name: "Dashboard" },
  { path: "pendingregistration", name: "Unpaid Pending Registration" },
  { path: "communication", name: "Communication" },
  { path: "dues", name: "Payment" },
  { path: "receipts", name: "Receipts" },
  { path: "membermanagement", name: "Member Management" },
  { path: "memberdirectories", name: "Member Directories" },
  { path: "paidregistration", name: "Paid Pending Registration" },
  { path: "paymenthistory", name: "Payment History" },
  { path: "receivepayment", name: "Receive Payment" },
  { path: "settings", name: "Settings" },
];
